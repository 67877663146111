
import { Component, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import WebMessage from '@/models/WebMessage'
import Agreement from '../../models/Agreement'

@Component({
  components: {
    Widget,
    DatePicker,
    FormInput,
    UserPicker,
    CompanyPicker,
    SelectPicker,
  },
})
export default class AgreementHome extends ViewModel {
  @Prop({ default: null })
  public id!: string;

  public title = 'Create Document';

  public agreement: Agreement = new Agreement();

  public file: any = null;

  public busy = true;

  public upload_file = true;

  public can_edit = false;

  public agreement_types: any = [
    {
      name: 'Publisher Agreement',
      value: 'publisher_agreement',
    },
    {
      name: 'Advertiser Agreement',
      value: 'advertiser_agreement',
    },
    {
      name: 'Agency Agreement',
      value: 'agency_agreement',
    },
    {
      name: 'Other',
      value: 'other',
    },
  ];

  public created() {
    if (this.id) {
      this.loading = true
      Agreement.get(this.id).then(o => {
        if (o instanceof Agreement) {
          this.agreement = o

          this.title = 'Edit Document'

          this.loading = false
          this.busy = false

          this.can_edit = this.agreement.id == null || this.agreement.client_id == null

          if (!this.can_edit) {
            WebMessage.warning(
              'This document is complete, you are not able to edit it.',
            )
          }
        } else {
          return this.newAgreement()
        }
      })
    } else {
      this.newAgreement()
    }
  }

  public newAgreement() {
    this.loading = false
    this.busy = false
    this.can_edit = true
  }

  public onSubmit() {
    this.loading = true
    this.agreement
      .save(this.file)
      .then(response => {
        this.loading = false
        if (response.status == 200) {
          this.$router.push({ name: 'AgreementHome' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public cancel() {
    this.$router.push({ name: 'AgreementHome' })
  }
}
