
import { Component, Ref } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import Widget from '@/components/Widget/Widget.vue'
import Agreement from '@/models/Agreement'
import Api from '@/models/Api'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
  },
})
export default class AgreementHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public sort_by: string = 'number'

  public sort_desc: boolean = true

  public modal: any = {
    delete: false,
  }

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public query: string[] = [];

  public agreement: Agreement = new Agreement()

  public fields: any = [
    {
      key: 'number',
      label: '#',
      sortable: true,
      show: true,
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true,
      show: true,
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      show: true,
    },
    {
      key: 'client_id',
      label: 'Client',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'owner_id',
      label: 'AE',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'executed_at',
      label: 'Executed At',
      sortable: true,
      class: 'text-center',
      show: false,
    },
    {
      key: 'created_at',
      label: 'Created At',
      sortable: true,
      class: 'text-center',
      show: false,
    },
    {
      key: 'updated_at',
      label: 'Update At',
      sortable: true,
      class: 'text-center',
      show: false,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'action',
      label: 'Action',
      sortable: false,
      show: true,
    },
  ]

  public query_settings: any = {
    company_fields: [
      {
        name: 'client',
        key: 'client_id',
      },
    ],
    user_fields: [
      {
        name: 'ae',
        key: 'owner_id',
      },
    ],
    custom_fields: [
      {
        name: 'type:Publisher Agreement',
        value: 'type:publisher_agreement',
      },
      {
        name: 'type:Advertiser Agreement',
        value: 'type:advertiser_agreement',
      },
      {
        name: 'type:Agency Agreement',
        value: 'type:agency_agreement',
      },
      {
        name: 'type:Other',
        value: 'type:other',
      },
      {
        name: 'is:pending',
        value: 'is:pending',
      },
      {
        name: 'is:signed',
        value: 'is:signed',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public agreements(context: any) {
    this.loading = true
    return Agreement.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.filter,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public createAgreement() {
    this.$router.push('/app/agreement')
  }

  public editAgreement(id: string) {
    this.$router.push(`/app/agreement/${id}`)
  }

  public confirmDelete(agreement: Agreement) {
    this.agreement = agreement
    this.modal.delete = true
  }

  public deleteAgreement() {
    if (this.agreement.id) {
      this.agreement.delete().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public download(agreement: Agreement) {
    const api = new Api()
    api.download(
      `${process.env.VUE_APP_BASE_API_URL
      }agreement/${
        agreement.id
      }/download`,
      `#${agreement.number} - ${agreement.name}.pdf`,
    )
  }
}
